<template>
  <v-card>
    <v-card-title>Datos de la Licencia</v-card-title>
    <v-container v-if="error">
      <v-row>
        <v-col>
          <v-alert dense type="error">{{ error }}</v-alert>
        </v-col>
      </v-row>
    </v-container>
    <v-form v-model="formValidity" ref="form" @submit.prevent="">
      <v-container>
        <v-row>
          <v-col cols="12" md="4">
            <EmpleadoAutocomplete
              ref="empleadoAutoComplete"
              :empleadoGuardado="empleado"
              :conContratacion="true"
            ></EmpleadoAutocomplete>
          </v-col>
          <v-col cols="12" md="4">
            <MotivoAutocomplete
              ref="motivoAutoComplete"
              :motivoGuardado="motivo"
              tipoMotivo="licencia"
            >
            </MotivoAutocomplete>
          </v-col>
          <v-col cols="12" md="2">
            <v-menu
              ref="menuFechaDesde"
              v-model="menuFechaDesde"
              :close-on-content-click="false"
              :return-value.sync="fechaDesde"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fechaDesde"
                  label="Desde"
                  prepend-icon="mdi-calendar"
                  readonly
                  :rules="[(v) => !!v || 'Debe ingresar fecha desde']"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fechaDesde"
                no-title
                scrollable
                locale="es"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menuFechaDesde = false">
                  Cancelar
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menuFechaDesde.save(fechaDesde)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="2">
            <v-menu
              ref="menuFechaHasta"
              v-model="menuFechaHasta"
              :close-on-content-click="false"
              :return-value.sync="fechaHasta"
              transition="scale-transition"
              offset-y
              nudge-left="120"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fechaHasta"
                  label="Hasta"
                  prepend-icon="mdi-calendar"
                  readonly
                  :rules="fechaHastaRules"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fechaHasta"
                no-title
                scrollable
                locale="es"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menuFechaHasta = false">
                  Cancelar
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menuFechaHasta.save(fechaHasta)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12">
            <v-textarea
              v-model="observaciones"
              name="input-7-1"
              label="Observaciones"
              value=""
              hint="Ingrese texto"
              counter="500"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-divider> </v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!formValidity"
            color="primary"
            type="submit"
            :loading="loadingSave"
            @click="onSubmit()"
            ><v-icon left dark> mdi-check </v-icon>Guardar</v-btn
          >
          <v-btn color="error" to="/Novedades"
            ><v-icon left dark> mdi-close </v-icon>Cancelar</v-btn
          >
        </v-card-actions>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
import PresentismoService from "../services/PresentismoService.js";
import EmpleadoAutocomplete from "./autocompletes/EmpleadoAutocomplete.vue";
import MotivoAutocomplete from "./autocompletes/MotivoAutocomplete.vue";
import Helpers from "../../../ControlTss-Reloj-Web/src/helpers/helpers.js";
import moment from "moment";

export default {
  name: "ContratacionForm",
  components: {
    EmpleadoAutocomplete,
    MotivoAutocomplete,
  },

  data() {
    return {
      formValidity: false,
      error: false,
      fechaDesde: null,
      fechaHasta: null,
      menuFechaDesde: false,
      menuFechaHasta: false,
      empleado: null,
      motivo: null,
      observaciones: null,
      loadingSave: false,
      selectRules: [(v) => !!v || "Debe seleccionar una opción"],
      fechaHastaRules: [
        (v) => !!v || "Debe ingresar fecha hasta",
        (v) =>
          moment(v).format("YYYY-MM-DD") >=
            moment(this.fechaDesde).format("YYYY-MM-DD") ||
          'La fecha "Hasta" debe ser igual o mayor a la fecha "Desde"',
      ],
    };
  },
  props: {
    licencia: {
      type: Object,
      required: false,
    },
  },
  created() {
    if (this.licencia) {
      this.empleado = this.licencia.empleado;
      this.motivo = this.licencia.motivo;
      this.fechaDesde = this.licencia.desde;
      this.fechaHasta = this.licencia.hasta;
      this.observaciones = this.licencia.observaciones;
    }
  },

  methods: {
    onSubmit() {
      this.loadingSave = true;

      console.log(
        "empleado: " + this.$refs.empleadoAutoComplete.selEmpleado.id
      );
      console.log("empleado: " + this.$refs.empleadoAutoComplete.selEmpleado);
      console.log("motivo: " + this.$refs.motivoAutoComplete.selMotivo);

      let licenciaInstance = {
        // empleado: this.empleado.id,
        // motivo: this.motivo.id,
        // desde: this.fechaDesde,
        // hasta: this.fechaHasta,
        // observaciones: this.observaciones,

        empleado: this.$refs.empleadoAutoComplete.selEmpleado,
        motivo: this.$refs.motivoAutoComplete.selMotivo,
        desde: this.fechaDesde,
        hasta: this.fechaHasta,
        observaciones: this.observaciones,
      };

      if (this.licencia) {
        licenciaInstance.id = this.licencia.id;
        PresentismoService.putNovedad(licenciaInstance)
          .then((response) => {
            this.$router.push({
              name: "Novedades",
            });

            // this.$router.push({
            //   name: "EmpleadoDetalle",
            //   params: { id: licenciaInstance.id },
            // });
          })
          .catch((error) => {
            this.error = Helpers.handleError(error);
            this.loadingSave = false;
          });
      } else {
        PresentismoService.postLicencia(licenciaInstance)
          .then((response) => {
            this.$router.push({
              name: "Novedades",
            });
          })
          .catch((error) => {
            this.error = Helpers.handleError(error);
            this.loadingSave = false;
          });
      }
    },

    validate() {
      this.$refs.form.validate();
    },
  },
};
</script>
<style scoped>
.text-upper input {
  text-transform: uppercase;
}

.camera-viewport {
  position: absolute;

  /* left: -100px; */
  top: 0px;
  height: 140%;
  width: 140%;
}

.photo-viewport {
  position: absolute;
  top: 0px;
  width: 140%;
}
</style>
<style>
.overlay {
  position: absolute;
  left: 100%;
  transform: translateX(-96%) !important;
  height: 100%;
  width: auto;
  display: flex;
}
</style>
