<template>
  <v-container>
    <v-row>
      <v-col>
        <span
          v-if="novedad && novedad.get_tipo_display == 'Licencia'"
          class="text-h4"
          >Editar Licencia</span
        >
      </v-col>
    </v-row>
    <v-divider> </v-divider>
    <br />
    <div v-if="novedad && novedad.get_tipo_display == 'Licencia'">
      <LicenciaForm :licencia="novedad"></LicenciaForm>
    </div>
  </v-container>
</template>
<script>
import LicenciaForm from "../components/LicenciaForm.vue";
import PresentismoService from "@/services/PresentismoService.js";

export default {
  name: "NovedadEdit",
  components: {
    LicenciaForm,
  },
  data() {
    return {
      novedad: null,
    };
  },
  props: ["id"],
  created() {
    PresentismoService.getNovedad(this.id)
      .then((response) => {
        this.novedad = response.data;
      })
      .catch((error) => console.log(error));
  },
};
</script>
