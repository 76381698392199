<template>
  <v-autocomplete
    v-model="selMotivo"
    :items="motivos"
    :item-text="getMotivoText"
    item-value="id"
    label="Motivo"
    cache-items
    :loading="loading"
    :rules="motivoRules"
    :search-input.sync="searchMotivo"
    no-data-text="No se encontraron resultados"
    @change="onChange"
    ref="autocomplete"
    :disabled="disabled"
  >
  </v-autocomplete>
</template>

<script>
import PresentismoService from "@/services/PresentismoService";

export default {
  name: "MotivoAutocomplete",
  components: {},

  data() {
    return {
      formValidity: false,
      selMotivo: null,
      searchMotivo: null,
      motivos: [],
      loading: false,
      motivoRules: [(v) => !!v || "Debe seleccionar un motivo."],
      disabled: false,
    };
  },
  props: {
    motivoGuardado: {
      type: Object,
      required: false,
    },
    tipoMotivo: {
      type: String,
      required: true,
      // licencia, caso u horas
    },
  },
  created() {
    if (this.motivoGuardado) {
      this.selMotivo = this.motivoGuardado.id;
      this.motivos.push(this.motivoGuardado);
    }
  },
  methods: {
    onChange() {
      this.$emit(
        "motivo-changed",
        this.selMotivo,
        this.getMotivoText(
          this.selMotivo
            ? this.motivos.find((obj) => obj.id === this.selMotivo)
            : null
        )
      );
    },

    getMotivoText(item) {
      if (!item) return;
      return `${item.codigo} - ${item.nombre}`;
    },
    querySelections(v) {
      if (v.length < 3) return;
      this.loading = true;

      PresentismoService.getMotivos(v, this.tipoMotivo)
        .then((response) => {
          this.motivos = response.data.results;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
  },
  watch: {
    searchMotivo(val) {
      val && val !== this.selMotivo && this.querySelections(val);
    },
  },
};
</script>
